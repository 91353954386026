import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function AddTeam() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [country, setCountry] = useState('');
  const [image, setImage] = useState(null);
  const [gender, setGender] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('role', role);
    formData.append('country', country);
    formData.append('image', image);
    formData.append('gender', gender);

    try {
      const response = await axios.post('https://tiktokliveagency.com/tiktok/team/add_team', formData);
      console.log(response.data);
      // Reset form fields
      setName('');
      setRole('');
      setCountry('');
      setImage(null);
      setGender('');

        // Redirect to /viewTeam
        navigate('/viewTeam');

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <br />
      <div className="content-container">

      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}> Add New Team Memeber</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <label>Role:</label>
            <input type="text" value={role} onChange={(e) => setRole(e.target.value)} />
          </div>
          <div>
            <label>Country:</label>
            <select value={country} onChange={(e) => setCountry(e.target.value)}>
              <option value="">Select a country</option>
              <option value="UK">UK</option>
              <option value="USA">USA</option>
              <option value="China">China</option>
              <option value="India">India</option>
            </select>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <div>
            <label>Gender:</label>
            <div>
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={gender === '1'}
                  onChange={(e) => setGender(e.target.value)}
                />
                Male
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="2"
                  checked={gender === '2'}
                  onChange={(e) => setGender(e.target.value)}
                />
                Female
              </label>
            </div>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
}

export default AddTeam;