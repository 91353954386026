import React from "react";
import Team from "./pages/Team/Team";
function team() {
  return (
    <>
        <Team />
    </>
  );
}
export default team;
