import React, { useState, useEffect } from 'react';

import Questions from "../../components/Question";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Faq = ({ scrollToForm }) => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    // Fetch FAQs from PHP API
    fetch('https://tiktokliveagency.com/tiktok/faqs/fe_faq')
      .then(response => response.json())
      .then(data => setFaqs(data))
      .catch(error => console.error('Error fetching FAQs:', error));
  }, []);

  return (
    <>
      <div className="py-10">
        <div className="container mx-auto 2xl:px-0 px-4 xl:max-w-[1200px] w-full">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 mx-auto"></div>
          <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-bold text-center">
            FREQUENTLY ASKED <span className="text-[#EE1B50]">QUESTIONS</span>
          </h1>
          <p className="text-center text-[#737071] lg:text-base text-xs font-normal ">
            Find the answers for the most frequently asked questions about
            TikTok Live Agency below
          </p>

          <div className="mt-11 w-full space-y-2 md:border-t md:border-white md:border-opacity-10">
            {faqs.map((data, index) => (
              <div key={index}>
                <Questions
                  question={data.question}
                  answer={data.answer}
                  height={data.height}
                />
              </div>
            ))}
          </div>
          <NavLink to="/apply">
            <button className="md:mt-11 mt-6 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
              Apply Now To Join The Agency
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
};

function FaqsMain() {
  const pageTitle = "FAQ's | TikTok Live Agency";
  const metaDescription =
    "Find the answers for the most frequently asked questions about TikTok Live Agency";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="relative lg:pt-10 pt-10">
        <Faq />
      </div>
    </>
  );
}

export default FaqsMain;
