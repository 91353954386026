import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditFaqs = () => {
  const [rank, setRank] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const { faqId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFaq();
  }, []);

  const fetchFaq = async () => {
    try {
      const response = await axios.get(`https://tiktokliveagency.com/tiktok/faqs/fetch_faqs?id=${faqId}`);
      const faq = response.data;
      setRank(faq.rank);
      setQuestion(faq.question);
      setAnswer(faq.answer);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put('https://tiktokliveagency.com/tiktok/faqs/update_faq', {
        id: faqId,
        rank,
        question,
        answer,
      });
      navigate('/faqs');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <h2>Edit FAQ</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="rank">Rank:</label>
          <input
            type="number"
            id="rank"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="question">Question:</label>
          <textarea
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label htmlFor="answer">Answer:</label>
          <textarea
            id="answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Update FAQ</button>
      </form>
    </div>
  );
};

export default EditFaqs;