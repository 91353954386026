import React from "react";
import Stock from "../../assets/icons/stock.svg";
import Event from "../../assets/icons/event.svg";
import Vip from "../../assets/icons/vip.svg";
import Trend from "../../assets/icons/trend.svg";

import { NavLink } from "react-router-dom";

const Benefits = ({ scrollToForm }) => {
  // const OpenALink = () => {
  //   // Check if the page link exists
  //   if (j.link) {
  //     // Scroll to the top of the window
  //     window.scrollTo(0, 0);
  //   }
  // };

  const handleOnClick = () => {
    // scrollToForm();
    // openALink();
  };

  const program = [
    {
      i1: Stock,
      mtop: "lg:h-[212px]",
      text: "Exclusive opportunities to beta test new features on TikTok",
    },
    {
      i1: Event,
      mtop: "lg:mt-10",
      text: "Planned creator networking events, both digitally and in-person, to help grow your channel",
    },
    {
      i1: Vip,
      mtop: "lg:-mt-16",
      text: "VIP access to dedicated Partner-only support through your agency point of contact, to support on account bans, tech support, and more",
    },
    {
      i1: Trend,
      mtop: "lg:h-[185px]",
      text: "Monthly newsletter on latest TikTok news and trends",
    },
  ];
  return (
    <>
      <div className="bg-[#fbfbfb]">
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-20 pt-16">
          <div className="flex md:justify-between gap-5 items-center xl:flex-nowrap flex-wrap">
            <div className="md:max-w-[450px] w-full mx-auto">
              <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3"></div>
              <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-semibold">
                PROGRAM <span className="text-[#EE1B50]">BENEFITS</span>
              </h1>
              <p className="text-neutral-500 lg:text-lg text-xs font-medium md:mt-16 mt-3 md:mb-16 mb-7 text-justify">
                Join Prestige Perfections and open the door to a number of
                benefits you won’t get as a unmanaged creator, including:
              </p>
              <NavLink to="/apply">
              <button
                onClick={handleOnClick}
                className="text-center md:flex hidden text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10  justify-center items-center w-full"
              >
                Apply Now
              </button>
              </NavLink>
            </div>
            <div className="md:max-w-[650px] w-full mx-auto">
              <div className="flex lg:gap-6 gap-4 md:justify-between justify-center flex-wrap">
                {program.map((item) => {
                  return (
                    <div
                      key={Math.random(1000)}
                      className={`md:w-[310px] rounded-xl w-[180px] bg-white card-s md:pt-5 md:pb-6 pt-3 pb-4 md:px-5 px-3 ${item.mtop} `}
                    >
                      <div className="bg-[#FFCEDA] rounded-xl flex justify-center items-center md:w-[75px] md:h-[55px] w-[45px] h-10">
                        <img src={item.i1} alt="Image" className="md:w-8 w-5" />
                      </div>
                      <div className="text-neutral-700 lg:text-lg text-xs font-medium md:mt-7 mt-5 leading-snug">
                        {item.text}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <NavLink to="/apply">
            <button
              onClick={handleOnClick}
              className="text-center md:hidden flex text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10  justify-center items-center w-full"
            >
              Apply Today
            </button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
