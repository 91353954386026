import React from "react";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div style={{ 
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh" }}>
      <Helmet>
        <title>404 - Not Found</title>
        <meta name="description" content="The page you are looking for does not exist." />
        <meta name="robots" content="noindex" /> 
      </Helmet>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
