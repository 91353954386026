import { Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Base from "../src/layout/base/Base";
import Home from "../src/pages/Home/Main";
import FaqsMain from "./faq";
import CalculatorMain from "./diamond";
import ApplyMain from "./apply";
import TermsMain from "./terms";
import PrivacyMain from "./privacy";
import AboutMain from "./about";
import Team from "./team";
import Login from "./login";
import CareersMain from "./pages/Apply/Careers/CareersMain";
import Articles from "./pages/Article/Articles";
import ArticleDetails from "./pages/Article/ArticleDetails";
import Sitemap from "./sitemap";
import AllNews from "./pages/Admin/Articles/AdminPage";
import AddArticle from "./pages/Admin/Articles/AddArticle";
import AddFaqs from "./pages/Admin/Faqs/AddFaqs";
import EditFaqs from "./pages/Admin/Faqs/EditFaqs";
import ViewFaqs from "./pages/Admin/Faqs/ViewFaqs";
import EditArticle from "./pages/Admin/Articles/EditArticle";
import GoogleTagManager from "./GoogleTagManager";
import AddTeam from "./pages/Admin/Team/AddTeam";
import ViewTeam from "./pages/Admin/Team/viewTeam";
import ViewApplications from "./pages/Admin/Applications/ViewApplications";
import HostRoster from "./pages/Admin/HostRoster/HostRoster";
import AddHost from "./pages/Admin/HostRoster/AddHost";
import EditHost from "./pages/Admin/HostRoster/EditHost";
import ViewCommunication from "./pages/Admin/Comms/ViewComms";
import AddCommunication from "./pages/Admin/Comms/AddCommunication";
import NotFoundPage from "./NotFoundPage";

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state to track loading status
  const token = localStorage.getItem("token");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAdmin(token ? true : false);
    setIsLoading(false); // Set loading to false after isAdmin is set
  }, []);

  // Render loading spinner or skeleton while waiting for isAdmin to be set
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GoogleTagManager />
      <Base>
        <Routes>
          {/* Normal User Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/faqs" element={<FaqsMain />} />
          <Route path="/diamond-calculator" element={<CalculatorMain />} />
          <Route path="/apply" element={<ApplyMain />} />
          <Route path="/team" element={<Team />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/terms-and-conditions" element={<TermsMain />} />
          <Route path="/privacy-policy" element={<PrivacyMain />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/login" element={<Login setIsAdmin={setIsAdmin} />} />
          <Route path="/careers" element={<CareersMain />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:articleURL" element={<ArticleDetails />} />
          <Route path="/404" element={<NotFoundPage />} />

          {/* Admin Routes */}
          {isAdmin && (
            <>
              <Route path="/" element={<Home />} />

              <Route path="/allnews" element={<AllNews />} />
              <Route path="/addarticle" element={<AddArticle />} />
              <Route
                path="/edit-article/:articleId"
                element={<EditArticle />}
              />
              <Route path="/addTeam" element={<AddTeam />} />
              <Route path="/viewTeam" element={<ViewTeam />} />
              <Route path="/viewFaqs" element={<ViewFaqs />} />
              <Route path="/addFaqs" element={<AddFaqs />} />
              <Route path="/editFaqs" element={<EditFaqs />} />
              <Route path="/applications" element={<ViewApplications />} />
              <Route path="/HostRoster" element={<HostRoster />} />
              <Route path="/AddHost" element={<AddHost />} />
              <Route path="/EditHost/:hostId" element={<EditHost />} />
              <Route
                path="/ViewCommunication"
                element={<ViewCommunication />}
              />
              <Route path="/AddCommunication" element={<AddCommunication />} />
              <Route path="/404" element={<NotFoundPage />} />
            </>
          )}

          {/* Default Route */}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Base>
    </>
  );
}

export default App;
