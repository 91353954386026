import React from "react";
import Diamond from "./pages/DiamondCalculator/CalculatorMain";
function diamond() {
  return (
    <>
      <Diamond />
    </>
  );
}
export default diamond;
