import React from "react";
import About from "./pages/About/AboutMain"
function about() {
  return (
    <>
      <About />
    </>
  );
}
export default about;
