import React, { useState } from 'react';
import axios from 'axios';

const AddFaqs = () => {
  const [rank, setRank] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://tiktokliveagency.com/tiktok/faqs/add_faqs', {
        rank,
        question,
        answer,
      });
    
      console.log('Response:', response);
    
      if (response.data.status === 'success') {
        alert('FAQ added successfully');
        // Reset form fields
        setRank('');
        setQuestion('');
        setAnswer('');
      } else {
        // Display the error message to the user
        alert(`Failed to add FAQ: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while adding the FAQ');
    }
    
  
  
  
  };

  return (

    <div className="content-container">

<br/>
<br/><br/><br/><br/><br/>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="rank">Rank:</label>
        <input
          type="number"
          id="rank"
          value={rank}
          onChange={(e) => setRank(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="question">Question:</label>
        <textarea
          id="question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          required
        ></textarea>
      </div>
      <div>
        <label htmlFor="answer">Answer:</label>
        <textarea
          id="answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          required
        ></textarea>
      </div>
      <button type="submit">Add FAQ</button>
    </form>

    </div>


  );
};

export default AddFaqs;