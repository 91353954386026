import React from "react";
import Terms from "./pages/Terms/TermsMain";
import {Helmet} from "react-helmet";
function terms() {
  const pageTitle = "Terms and Conditions | TikTok Live Agency";
  const metaDescription =
    "Discover the story behind TikTok Live Agency. Learn about our mission, values, and the vibrant community we've built. Explore the exciting journey of creators and influencers. Join us on TikTok and be a part of our thriving community!";
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Terms />
    </>
  );
}
export default terms;
