import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import { NavLink } from "react-router-dom";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import { MEASUREMENT_ID } from "../../constants/constants";
import "./articles.css";
ReactGA.initialize(MEASUREMENT_ID);

const ArticleDetails = () => {
  const { articleURL } = useParams();
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentArticle, setcurrentArticle] = useState({});
  const [currentAuthor, setcurrentAuthor] = useState("");
  const [currentCategory, setcurrentCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log("adi", currentArticle);

  function generateMetaDescription(content) {
    const truncatedContent =
      content.substring(0, 155) + (content.length > 155 ? "..." : "");
    return truncatedContent;
  }

  const generateArticleSchema = (
    title,
    author,
    publishedAt,
    content,
    image,
    category,
    keywords
  ) => {
    return JSON.stringify({
      "@context": "https://schema.org/",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "tiktokliveagency.com/articles/" + currentArticle.URL, // Replace with actual URL
      },
      headline: title,
      author: {
        "@type": "Person",
        name: author,
      },
      datePublished: publishedAt,
      image: image,
      articleBody: content,
      keywords: keywords, // Array of relevant keywords
      category: category,
    });
  };
  useEffect(() => {
    console.log("aDDDD",currentArticle.content)
    if (currentArticle.content > 0) {
      console.log(currentArticle)
    }
  }, [currentArticle])



  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const responses = await Promise.all([
          fetch("https://tiktokliveagency.com/tiktok/authors/fetchAuthors", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
          fetch(
            "https://tiktokliveagency.com/tiktok/categories/fetchCategories",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Sclout",
              },
            }
          ),
          fetch("https://tiktokliveagency.com/tiktok/articles/fetchArticles", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
        ]);

        const [authorsRes, categoriesRes, articlesRes] = await Promise.all(
          responses.map((res) => res.json())
        );

        if (
          !authorsRes.data ||
          !categoriesRes.data ||
          !articlesRes.data ||
          !articleURL
        ) {
          throw new Error("Failed to fetch data or invalid article URL");
        }

        setAuthors(authorsRes.data.authors);
        setcurrentArticle(
          articlesRes.data.find((article) => article.URL === articleURL)
        );
        setCategories(categoriesRes.data);
        setArticles(articlesRes.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [articleURL]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false, // Use 24-hour format
    };
    return date.toLocaleDateString("en-US", options);
  }
  useEffect(() => {
    if (currentArticle && authors.length > 0 && categories.length > 0) {
      const author = authors.find(
        (author) => author.id === currentArticle.authorID
      );
      const category = categories.find(
        (category) => category.CategoryID === currentArticle.categoryID
      );
      setcurrentAuthor(author);
      setcurrentCategory(category);
    }
  }, [currentArticle, authors, categories]);

  return (
    <div className="article-details">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Helmet>
            <title>{currentArticle.title}</title>
            <meta
              name="description"
              content={generateMetaDescription(currentArticle.content)}
            />
            {/* <meta name="keywords" content={currentArticle.keywords.join(", ")} /> */}

            {/* Open Graph tags */}
            <meta
              property="og:url"
              content={`https://tiktokliveagency.com/articles/${currentArticle.URL}`}
            />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={currentArticle.title} />
            <meta
              property="og:description"
              content={generateMetaDescription(currentArticle.content)}
            />
            <meta property="og:image" content={currentArticle.image} />
            <meta property="og:image:alt" content={currentArticle.title} />
            <meta property="og:site_name" content="TikTok Live Agency" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={currentArticle.title} />
            <meta
              name="twitter:description"
              content={generateMetaDescription(currentArticle.content)}
            />
            <meta name="twitter:image" content={currentArticle.image} />

            {/* Article schema */}
            <script type="application/ld+json">
              {generateArticleSchema(
                currentArticle.title,
                currentAuthor.name,
                currentArticle.publishedAt,
                currentArticle.content,
                currentArticle.image,
                // currentCategory.CategoryName,
                currentArticle.keywords
              )}
            </script>
          </Helmet>

          <h2 className="article-title">{currentArticle.title}</h2>
          <p className="article-author">
            {currentAuthor ? currentAuthor.name : "Loading..."} |
            {new Date(currentArticle.publishedAt)
              .toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
              .replace(",", "")
              .replace(/(\d+)(st|nd|rd|th)/, "$1<sup>$2</sup>")}
          </p>
          <img
            src={currentArticle.image}
            alt={currentArticle.title}
            className="article-image"
          />
          <div
            className="article-content"
            dangerouslySetInnerHTML={{
              __html: decodeHtmlEntities(currentArticle.content),
            }}
          />

          <NavLink to="/apply">
            <button className="md:mt-11 mt-6 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
              Apply Now To Join The Agency
            </button>
          </NavLink>
        </>
      )}
    </div>
  );
};
const decodeHtmlEntities = (html) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = html;
  let decodedText = textarea.value;
  decodedText = decodedText.replace(/\r\n/g, ""); // Remove \r\n characters
  return decodedText;
};


export default ArticleDetails;
