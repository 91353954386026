import React, { useRef } from "react";
import Hero from "./Hero";
import Provide from "./Provide";
import Benefits from "./Benefits";
import Tiktock from "./Tiktok";
import AdminPage from "../Admin/Articles/AdminPage";

const Main = () => {
  const formRef = useRef();

  const isAdmin = localStorage.getItem("token");
  // if (isAdmin) {
  //   return (
  //     <AdminPage />
  //   )
  // }


  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Hero scrollToForm={scrollToForm} />
      <Provide scrollToForm={scrollToForm} />
      <Benefits scrollToForm={scrollToForm} />
      <Tiktock />
    </>
  );
};

export default Main;
