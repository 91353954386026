import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

function ViewTeam() {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    fetchTeam();
  }, []);

  const fetchTeam = async () => {
    try {
      const response = await axios.get('https://tiktokliveagency.com/tiktok/team/view_team');
      if (Array.isArray(response.data)) {
        setTeam(response.data);
      } else {
        setTeam([]);
      }
    } catch (error) {
      console.error(error);
      setTeam([]);
    }
  };

  const handleEdit = (id) => {
    // Redirect to the edit page with the team member ID
    window.location.href = `/edit_team?id=${id}`;
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this team member?");
    if (confirmDelete) {
      try {
        const response = await axios.delete(`https://tiktokliveagency.com/tiktok/team/delete_team?id=${id}`);
        console.log(response.data); // Log the response data
        fetchTeam(); // Refresh the team list after deletion
      } catch (error) {
        console.error("Delete error:", error);
      }
    }
  };
  return (
    <div className="content-container">
      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
        Team Members
      </h1>
      <Link to="/addTeam">
        <button type="submit" className="submit-button">Add A New Team Member</button>
      </Link>
      <table className="articles-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Country</th>
            <th>Gender</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {team.map((member) => (
            <tr key={member.id}>
              <td>{member.name}</td>
              <td>{member.role}</td>
              <td>{member.country}</td>
              <td>{member.gender === '1' ? 'Male' : 'Female'}</td>
              <td className="action-buttons">
                {/* <button onClick={() => handleEdit(member.id)}>Edit</button> */}
                <button className="delete-button" onClick={() => handleDelete(member.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ViewTeam;