import React, { useState, useEffect } from "react";
import SaqibTikTok from "../../assets/images//Home/saqib.jpg";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Calculator = () => {
  const [diamondsReceived, setDiamondsReceived] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [conversionRate, setConversionRate] = useState(null);
  const [creatorRank, setCreatorRank] = useState("");

  useEffect(() => {
    // Fetch the latest exchange rates from a free API
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch("https://open.er-api.com/v6/latest/USD"); // Replace 'USD' with the currency code of your country
        const data = await response.json();
        setConversionRate(data.rates.GBP);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, []);

  const calculateEarnings = () => {
    const earningsPerDiamond = 5 / 1000;
    const earnings = diamondsReceived * earningsPerDiamond;
    setTotalEarnings(earnings);

    if (diamondsReceived > 500000) {
      setCreatorRank("VIP");
    } else if (diamondsReceived >= 100000) {
      setCreatorRank("Thriving");
    } else if (diamondsReceived >= 20000) {
      setCreatorRank("Striving");
    } else {
      setCreatorRank("Starter");
    }
  };

  return (
    <>
      <div className="bg-white">
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-24 pt-10">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-1 md:hidden block mr-auto"></div>
          <h1 className="text-[#242527] font-semibold lg:text-[46px] text-left text-[28px] md:hidden block uppercase">
            About Us
          </h1>

          <div className="flex md:justify-between justify-center gap-5 items-center xl:flex-nowrap flex-wrap">
            <div className="md:max-w-[580px] w-full mx-auto">
              <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 md:block hidden"></div>
              <h1 className="text-[#242527] font-semibold lg:text-[46px] text-[28px] md:block hidden uppercase">
                About Us
              </h1>
              <div className="md:max-w-[550px] w-full mx-auto relative md:hidden block mt-4">
                <img
                  src={SaqibTikTok}
                  alt="Image"
                  className="w-full rounded-xl"
                />
              </div>

              <p className="mt-5 md:mb-10 mb-5 text-neutral-500 lg:text-base text-xs font-normal text-justify">
                At Prestige Perfections, we are not just a TikTok Live
                agency; we are architects of dreams, catalysts of creativity,
                and champions of a new era in digital empowerment. I am Saqib
                Malik, the proud Director of this venture, and it is my pleasure
                to share our story with you.
                <br />
                <br />
                Driven by a passion for fostering success, we embarked on this
                journey after scaling new heights in influencer marketing.
                Having witnessed the transformative power of TikTok in shaping
                careers and stories, we felt compelled to extend our expertise
                to the community. Prestige Perfections was born out of a
                vision to empower individuals, break free from the constraints
                of a traditional 9-to-5, and redefine success in the digital
                age.
                <br />
                <br />
                At the heart of our agency is a commitment to excellence. We
                don't just facilitate TikTok Live experiences; we sculpt them
                with precision, leveraging our insights, industry knowledge, and
                a touch of creativity that sets us apart. Our success is not
                measured merely in numbers but in the stories of the creators
                we've helped thrive, turning their passion into a sustainable
                living.
                <br />
                <br />
                Our mission is clear – to democratize success in the digital
                space. We aim to be the bridge that connects aspiring TikTok
                creators with opportunities that extend beyond the screen. By
                providing a platform where innovation meets monetization, we are
                dismantling the barriers to entry and empowering a new wave of
                entrepreneurs.
                <br />
                <br />
                Whether you're an emerging TikTok talent or a brand seeking
                authentic connections, we invite you to be part of the Prestige
                Perfections family. Let's rewrite the narrative of success
                together, embracing the limitless possibilities that TikTok
                offers.
                <br />
                <br />
                Thank you for considering us as your partner in this exciting
                journey. Here's to breaking norms, fostering creativity, and
                achieving excellence, one TikTok at a time.
              </p>

              <p className="mt-5 md:mb-10 mb-5 text-neutral-500 lg:text-base text-xs font-normal"></p>
            </div>
            <div className="md:max-w-[550px] w-full mx-auto relative md:block hidden">
              <img
                src={SaqibTikTok}
                alt="Image"
                className="w-full rounded-xl"
              />
              {/* <p className="text-black lg:text-lg text-base font-semibold">
                Saqib Malik | Founder of Prestige TikTok Live Agency
              </p> */}
            </div>
          </div>

          <NavLink to="/apply">
            <button className="md:mt-11 mt-6 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
              Apply Now To Join The Agency
            </button>
          </NavLink>
          <br />
        </div>
      </div>
    </>
  );
};

function AboutMain() {

  const pageTitle = "About Us | TikTok Live Agency";
  const metaDescription = "Discover the story behind TikTok Live Agency. Learn about our mission, values, and the vibrant community we've built. Explore the exciting journey of creators and influencers. Join us on TikTok and be a part of our thriving community!";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="relative lg:pt-10 pt-10">
        <Calculator />
      </div>
    </>

  );
}

export default AboutMain;
