import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./adminPage.css";
import { EditorState, ContentState, convertFromHTML,convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactMarkdown from "react-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { convertToHTML } from "draft-convert";

function EditArticle() {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [initialArticle, setInitialArticle] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `https://tiktokliveagency.com/tiktok/articles/fetchArticle?id=${articleId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }
        );
        const data = await response.json();
        setArticle(data.message);
        console.log("data",data)

        if (data.message && data.message.content) {
          console.log("data content", data.message.content)
          const contentState = convertFromRaw(JSON.parse(
            data.message.content
          ));
          setEditorState(EditorState.createWithContent(contentState));
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };


    const fetchAuthorsAndCategories = async () => {
      try {
        const [authorsResponse, categoriesResponse] = await Promise.all([
          fetch("https://tiktokliveagency.com/tiktok/authors/fetchAuthors", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
          fetch(
            "https://tiktokliveagency.com/tiktok/categories/fetchCategories",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Sclout",
              },
            }
          ),
        ]);
        const authorsData = await authorsResponse.json();
        const categoriesData = await categoriesResponse.json();
        setAuthors(authorsData.data.authors);
        setCategories(categoriesData.data);
      } catch (error) {
        console.error("Error fetching authors and categories:", error);
      }
    };

    fetchArticle();
    fetchAuthorsAndCategories();
  }, [articleId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setArticle((prevArticle) => ({
      ...prevArticle,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedFields = {};
      Object.entries(article).forEach(([key, value]) => {
        if (value !== initialArticle[key]) {
          updatedFields[key] = value;
        }
      });

      const response = await fetch(
        `https://tiktokliveagency.com/tiktok/articles/updateArticle?id=${articleId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Sclout",
          },
          body: JSON.stringify(updatedFields),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update article");
      }
      console.log("Article updated successfully!");
      alert("Article updated successfully!");
      navigate("/admin");
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-container">
      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
        Edit Article
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="title" className="field-label">
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className="input-field"
            value={article.title || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group2">
          <label htmlFor="content" className="field-label">
            Content:
          </label>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-content"
            editorStyle={{ minHeight: "300px" }}
            toolbar={{
              options: ["inline", "link"],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              link: {
                popupClassName: "link-popup",
              },
            }}
          />
        </div>
        <div className="input-group">
          <label htmlFor="publishedAt" className="field-label">
            Published At:
          </label>
          <input
            type="datetime-local"
            id="publishedAt"
            name="publishedAt"
            className="input-field"
            value={article.publishedAt || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="authorID" className="field-label">
            Author:
          </label>
          <select
            id="authorID"
            name="authorID"
            className="input-field"
            value={article.authorID || ""}
            onChange={handleInputChange}>
            <option value="">Select an author</option>
            {authors.map((author) => (
              <option key={author.id} value={author.id}>
                {author.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="image" className="field-label">
            Image URL:
          </label>
          <input
            type="text"
            id="image"
            name="image"
            className="input-field"
            value={article.image || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="categoryID" className="field-label">
            Category:
          </label>
          <select
            id="categoryID"
            name="categoryID"
            className="input-field"
            value=""
            onChange={handleInputChange}>
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.CategoryID} value={category.CategoryName}>
                {category.CategoryName}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="URL" className="field-label">
            URL:
          </label>
          <input
            type="text"
            id="URL"
            name="URL"
            className="input-field"
            value={article.URL || ""}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" className="submit-button">
          Update
        </button>
      </form>
    </div>
  );
}

export default EditArticle;
