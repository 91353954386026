import React, { useEffect, useState } from "react";
import "./adminPage.css";
import { useNavigate, Link } from "react-router-dom";


const AllNews = () => {


  const [articles, setArticles] = useState([]);
  const token = localStorage.getItem("token")
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    publishedAt: "",
    authorID: "",
    image: "",
    categoryID: "",
    URL: "",
  });


  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const responses = await Promise.all([
          fetch("https://tiktokliveagency.com/tiktok/authors/fetchAuthors", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
          fetch(
            "https://tiktokliveagency.com/tiktok/categories/fetchCategories",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Sclout",
              },
            }
          ),
          fetch("https://tiktokliveagency.com/tiktok/articles/fetchArticles", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
        ]);

        const [authorsRes, categoriesRes, articlesRes] = await Promise.all(
          responses.map((res) => res.json())
        );
        console.log(categories);

        if (!authorsRes.data || !categoriesRes.data || !articlesRes.data) {
          throw new Error("Failed to fetch data");
        }

        setAuthors(authorsRes.data.authors);
        setCategories(categoriesRes.data);
        setArticles(articlesRes.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);


  const handleEdit = (articleId) => {
    navigate(`/edit-article/${articleId}`);
  };

  const handleDelete = async (articleId) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this article?");
      if (confirmDelete) {
        const response = await fetch(
          `https://tiktokliveagency.com/tiktok/articles/delete?id=${articleId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to delete article");
        }
        console.log("Article deleted successfully!");
        alert("Article deleted successfully!");
        const updatedArticles = articles.filter((article) => article.id !== articleId);
        setArticles(updatedArticles);
      }
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };


  return (
    <>
    
    <div className="content-container">
      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}> All News Articles</h1>
        <Link to="/addarticle">
          <button type="submit" className="submit-button">Add A New Article</button>
        </Link>
      <table className="articles-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Image</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article) => (
            <tr key={article.id}>
              <td>{article.id}</td>
              <td>{article.title}</td>
              <td>
                <img
                  src={article.image}
                  alt={article.title}
                  width="50"
                  height="50" />
              </td>
              <td>{new Date(article.publishedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).replace(',', '').replace(/(\d+)(st|nd|rd|th)/, '$1<sup>$2</sup>')}</td>
              <td className="action-buttons">
                <button onClick={() => handleEdit(article.id)}>Edit</button>
                <button
                  onClick={() => handleDelete(article.id)}
                  className="delete-button">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div></>



  )
}

export default AllNews