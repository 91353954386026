import React from "react";
import { NavLink } from "react-router-dom";

import Case from "../../assets/icons/case.svg";
import Tag from "../../assets/icons/tag.svg";
import Clock from "../../assets/icons/clock.svg";
import Sec from "../../assets/icons/sec.svg";
const Provide = ({ scrollToForm }) => {
  const provide = [
    {
      i1: Case,
      text: "Provide tailored TikTok campaigns & access to events that fuel community growth",
    },
    {
      i1: Tag,
      text: "Access to 1-on-1 coaching and content strategy planning",
    },
    {
      i1: Clock,
      text: "Dedicated support from your Partner Manager on issues regarding Revenue, Content",
    },
    {
      i1: Sec,
      text: "Reviews, Platform Bans, New Features & Latest Trends",
    },
  ];
  return (
    <>
      <div className="bg-[#fbfbfb]">
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-24 pt-16">
          <div className="flex md:justify-between gap-5 items-center xl:flex-nowrap flex-wrap">
            <div className="md:max-w-[430px] w-full">
              <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3"></div>
              <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-semibold">
                WHAT WE <span className="text-[#EE1B50]">PROVIDE</span>{" "}
              </h1>
            </div>
            <div className="md:max-w-[610px] w-full">
              <p className="text-neutral-500 lg:text-lg text-xs font-medium text-justify">
                When you join Prestige Perfections through our TikTok Creator
                Program, YOU, the creator, always come first. We will do
                everything we can to help grow your community through a variety
                of services. We’re industry leading experts on TikTok LIVE,
                working with some of the best live streamers and content
                creators.
              </p>
            </div>
          </div>
          <div className="lg:mt-20 mt-9 flex lg:gap-6 gap-4 md:justify-between justify-center flex-wrap">
            {provide.map((item) => {
              return (
                <div
                  key={Math.random(1000)}
                  className="md:w-[288px] w-[176px] bg-white card-s md:pt-5 md:pb-6 pt-3 pb-4 md:px-5 px-3"
                >
                  <div className="bg-[#FFCEDA] rounded-xl flex justify-center items-center md:w-[75px] md:h-[55px] w-[45px] h-10 mx-auto">
                    <img
                      src={item.i1}
                      alt="Image"
                      className="md:w-8 w-5 mx-auto"
                    />
                  </div>
                  <div className="text-center text-neutral-700 lg:text-lg text-xs font-medium md:mt-7 mt-5 leading-snug">
                    {item.text}
                  </div>
                </div>
              );
            })}
          </div>

          <NavLink to="/apply">
            <button className="md:mt-16 mt-7 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
              Apply Now
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Provide;
