import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link} from "react-router-dom";


import "../Articles/adminPage.css"
const ViewFaqs = () => {
  const [faqs, setFaqs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get('https://tiktokliveagency.com/tiktok/faqs/fetch_faqs');
      setFaqs(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEdit = (faqId) => {
    navigate(`admin/updatefaqs/${faqId}`);
  };

  const handleDelete = async (faqId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this FAQ?");
    if (confirmDelete) {
      try {
        await axios.delete(`https://tiktokliveagency.com/tiktok/faqs/delete_faq?id=${faqId}`);
        // Refresh the FAQs list after successful deletion
        fetchFaqs();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  

  return (
   


<div className="content-container">
      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
        Frequently Asked Questions
      </h1>
      <Link to="/addFaqs">
        <button type="submit" className="submit-button">Add A New FAQ</button>
      </Link>


     
      <table className="articles-table">
        <thead>
          <tr>
         
            <th>Question</th>
            <th>Answer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq, index) => (
            <tr key={index}>
        
              <td>{faq.question}</td>
              <td>{faq.answer}</td>
              <td className="action-buttons">
                <button className="action-buttons" onClick={() => handleEdit(faq.id)}>Edit</button>
                <button className="delete-button" onClick={() => handleDelete(faq.id)}>Delete</button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewFaqs;