import React, { useState, useEffect } from "react";
import axios from "axios";

const AddCommunication = () => {
  const [formData, setFormData] = useState({
    hosts: [],
    method: "",
    message: "",
  });
  const [hosts, setHosts] = useState([]);

  useEffect(() => {
    fetchHosts();
  }, []);

  const fetchHosts = async () => {
    try {
      const response = await axios.get("https://tiktokliveagency.com/tiktok/hosts/get_hosts");
      setHosts(response.data);
    } catch (error) {
      console.error("Error fetching hosts:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleHostChange = (e) => {
    const selectedHosts = Array.from(e.target.selectedOptions, (option) => option.value);
    setFormData({ ...formData, hosts: selectedHosts });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://tiktokliveagency.com/tiktok/communications/add_communication", formData);
      console.log(response.data);
      // Reset form data
      setFormData({
        hosts: [],
        method: "",
        message: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="admincontainer">
      <div className="add-communication-container">
        <h2>Add Communication</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="hosts">Hosts:</label>
            <select
              id="hosts"
              name="hosts"
              multiple
              value={formData.hosts}
              onChange={handleHostChange}
              required
            >
              {hosts.map((host) => (
                <option key={host.id} value={host.id}>
                  {host.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="method">Method:</label>
            <select
              id="method"
              name="method"
              value={formData.method}
              onChange={handleChange}
              required
            >
              <option value="">Select Method</option>
              <option value="Email">Email</option>
              <option value="SMS">SMS</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Send Communication</button>
        </form>
      </div>
    </div>
  );
};

export default AddCommunication;