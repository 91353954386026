import React, { useState } from "react";
import Fi from "../../assets/images/Home/form.png";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";


const Form = ({ formRef }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleApplyNow = () => {
    const fullName = document.getElementById("full_name").value;
    const email = document.getElementById("email").value;
    const phoneNumber = document.getElementById("phone_number").value;
    const username = document.getElementById("username").value;
    const invitationCode = document.getElementById("invitation_code").value;
    const followerCount = document.getElementById("follower_count").value;
    const country = document.getElementById("country").value;
    const earnings_over_150k = document.querySelector('input[name="earnings_over_150k"]:checked').value;

    if (!fullName || !email || !phoneNumber || !username || !invitationCode || !followerCount || !country) {
      alert("Please fill in all required fields.");
      return;
    }

    if (!isChecked) {
      alert("Please accept the terms and conditions.");
      return;
    }

    const formData = {
      name: fullName,
      username,
      email,
      phoneNumber,
      country,
      followersCount: followerCount,
      inviteCode: invitationCode,
      earnings_over_150k
    };

    fetch("https://tiktokliveagency.com/tiktok/applications/insertApplication", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data.success) {
        return fetch("https://tiktokliveagency.com/send_email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
      } else {
        console.error("Server error:", data.message);
        throw new Error("Failed to submit application.");
      }
    })
    .then((emailResponse) => {
      if (!emailResponse.ok) {
        throw new Error(`Network response was not ok: ${emailResponse.statusText}`);
      }
      return emailResponse.json();
    })
    .then((emailData) => {
      if (emailData.success) {
        alert("Your application has been submitted!");
        window.location.href = "https://www.tiktok.com/@tiktokliveagency/";
      } else {
        console.error("Failed to send email:", emailData.message);
        throw new Error("Failed to send email.");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    });
  };
  

  return (
    <>
      <div ref={formRef}>
        <img src={Fi} alt="Image" className="absolute -z-10 w-full h-full" />
        <div className="xl:max-w-[1200px] w-full mx-auto 2xl:px-0 px-4 lg:pt-20 lg:pb-24 pt-10 pb-14">
          <div className="w-[130px] h-[9px] bg-[#EE1B50] mb-1 lg:ml-7"></div>
          <div className="w-full md:text-center text-white lg:text-[40px] text-2xl font-semibold uppercase lg:leading-[50px]">
            Apply now and one of our team members will let you know if you
            qualify to join or not
          </div>
          <form
            action=""
            className="md:mt-20 mt-10 flex gap-x-4 md:justify-between flex-wrap md:gap-y-6 gap-y-4"
          >
            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                Full Name
              </p>
              <input
                type="text"
                id="full_name"
                placeholder="Your Full Name"
                required
                className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
            </div>
            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                What is your Invitation Code?
                <br />
                <span className="text-[#EE1B50] lg:text-sm text-xs">
                  Get your invitation code by clicking{" "}
                  <a
                    href="https://vm.tiktok.com/ZMj91aMXW/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    [https://vm.tiktok.com/ZMj91aMXW/]
                  </a>{" "}
                  or by going to TikTok &gt; Settings and privacy &gt; Creator
                  tools &gt; LIVE Center &gt; Agency Center &gt; Click the
                  Generate Button.
                </span>
              </p>
              <input
                type="text"
                id="invitation_code"
                placeholder="Invitation Code"
                required
                className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
            </div>
            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                Your TikTok Username
              </p>
              <input
                type="text"
                id="username"
                placeholder="@Username"
                required
                className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
            </div>
            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                Email address
              </p>
              <input
                type="email"
                id="email"
                placeholder="Email@gmail.com"
                required
                className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal md:max-w-[517px] w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
            </div>
            <div className="md:max-w-[517px] w-full mx-auto flex md:gap-8 gap-4">
              <div className="w-full">
                <p className="text-white lg:text-lg text-xs font-normal mb-1">
                  Phone Number
                </p>
                <input
                  type="number"
                  id="phone_number"
                  placeholder="Enter your phone Number"
                  required
                  className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
                />
              </div>
            </div>


<div className="md:max-w-[517px] w-full mx-auto flex md:gap-8 gap-4">
  <div className="w-full">
    <p className="text-white lg:text-lg text-xs font-normal mb-1">Select Country</p>
    <select id="country" required className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px]">
      <option value="" selected disabled>Please select your country</option>
      <option value="UK">United Kingdom</option>
      <option value="US">United States</option>
      <option value="AL">Albania</option>
      <option value="NL">Netherlands</option>
      <option value="GR">Greece</option>
      <option value="IE">Ireland</option>
      <option value="LT">Lithuania</option>
      <option value="RS">Serbia</option>
      <option value="LV">Latvia</option>
      <option value="EE">Estonia</option>
      <option value="MK">North Macedonia</option>
      <option value="BA">Bosnia and Herzegovina</option>
      <option value="MT">Malta</option>
      <option value="ME">Montenegro</option>
      <option value="FO">Faroe Islands</option>
      <option value="GI">Gibraltar</option>
      <option value="JE">Jersey</option>
      <option value="IM">Isle of Man</option>
      <option value="GG">Guernsey</option>
      <option value="CD">Democratic Republic of the Congo</option>
      <option value="SM">San Marino</option>
      <option value="SJ">Svalbard and Jan Mayen</option>
    </select>
  </div>
</div>


            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                How many followers do you have on TikTok?
              </p>
              <input
                type="text"
                id="follower_count"
                placeholder="How many tik tok followers you have"
                required
                className="mt-3 bg-white outline-none text-neutral-500 lg:text-base text-xs font-normal md:max-w-[517px] w-full md:h-[65px] h-[50px] lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
            </div>
            <div className="md:max-w-[517px] w-full mx-auto">
              <p className="text-white lg:text-lg text-xs font-normal mb-1">
                Have you earned more than 150,000 diamonds in the last 30 days
                via TikTok LIVE?
                <span className="text-[#EE1B50] lg:text-sm text-xs">
                  (has to be less than this to be eligible)
                </span>
              </p>
              <div className="flex lg:gap-20 gap-8 items-center mt-8">
                <div className="flex gap-4 items-center">
                  <input
                    type="radio"
                    id="earnings-no"
                    name="earnings_over_150k"
                    value="no"
                    checked={!isChecked1}
                    onChange={() => setIsChecked1(false)}
                    required
                  />
                  <label
                    htmlFor="earnings-no"
                    className="lg:text-lg text-xs text-white"
                  >
                    No
                  </label>
                </div>
                <div className="flex gap-4 items-center">
                  <input
                    type="radio"
                    id="earnings-yes"
                    name="earnings_over_150k"
                    value="yes"
                    checked={isChecked1}
                    onChange={() => setIsChecked1(true)}
                    required
                  />
                  <label
                    htmlFor="earnings-yes"
                    className="lg:text-lg text-xs text-white"
                  >
                    Yes
                  </label>
                </div>
              </div>
            </div>
            <hr className="border border-[#AFAFAD] md:my-12 my-9" />
            <div className="flex gap-8 items-center md:px-9 md:justify-start justify-center">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className="md:w-[30px] md:h-[30px] w-[24px] h-[24px] rounded-full cursor-pointer"
                required
              />
              <label
                htmlFor="terms-checkbox"
                className="lg:text-lg text-xs text-white"
              >
                Please tick this box to confirm you agree to our{" "}
                <NavLink to="/terms-and-conditions">
                  <span className="text-[#EE1B50]">terms and conditions.</span>
                </NavLink>
              </label>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault(); // Prevent the default form submission
                handleApplyNow();
              }}
              className="md:mt-11 mt-6 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full"
            >
              Apply Now
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

function ApplyMain() {

  const pageTitle ="Apply to Join the Agency | TikTok Live Agency";
  const metaDescription = "Apply to join TikTok Live Agency and explore exciting opportunities. Complete the application form to become a part of our community. Join now!";


  return (
   <> 
    <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={metaDescription} />
  </Helmet>
    <div className="relative lg:pt-10 pt-10">
      <Form />
    </div>
    </>
  );
}

export default ApplyMain;
