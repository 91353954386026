import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const HostRoster = () => {
  const [hosts, setHosts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchHosts();
  }, []);

  const fetchHosts = async () => {
    try {
      const response = await axios.get("https://tiktokliveagency.com/tiktok/hosts/get_hosts");
      setHosts(response.data);
    } catch (error) {
      console.error("Error fetching hosts:", error);
    }
  };

  const handleAddHost = () => {
    navigate("/AddHost");
  };

  const handleEditHost = (host) => {
    navigate(`/EditHost/${host.id}`, { state: { host } });
  };

  const handleDeleteHost = async (hostId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this host?");
    if (confirmDelete) {
      try {
        await axios.delete(`https://tiktokliveagency.com/tiktok/hosts/delete_host?id=${hostId}`);
        fetchHosts(); // Refresh the host list after deletion
      } catch (error) {
        console.error("Error deleting host:", error);
      }
    }
  };

  return (
    <>
      <div className="admincontainer">
        <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
          Prestige Perfections UK+ & _US Agency Hosts
        </h1>
        <button className="submit-button" onClick={handleAddHost}>Add Host</button>
        <table className="applications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Number</th>
              <th>Country</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Contact Preference</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {hosts.map((host) => (
              <tr key={host.id}>
                <td>{host.id}</td>
                <td>{host.name}</td>
                <td>{host.username}</td>
                <td>{host.email}</td>
                <td>{host.number}</td>
                <td>{host.country}</td>
                <td>{host.creator_status}</td>
                <td>{host.start}</td>
                <td>{host.end}</td>
                <td>{host.contact_pref}</td>
                <td className="action-buttons">
                  <button onClick={() => handleEditHost(host)}>Edit</button>
                  <button className="delete-button" onClick={() => handleDeleteHost(host.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HostRoster;