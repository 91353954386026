import React, { useState, useEffect } from "react";
import Tik from "../../assets/images/Home/cal.png";
import { Helmet } from "react-helmet";

const Calculator = () => {
  const pageTitle = "TikTok Diamonds to USD & GBP Converter | TikTok Live Agency";
  const metaDescription = "Discover Your Earnings in USD and GBP! Explore our TikTok Diamonds to USD & GBP Calculator - the ultimate tool for live creators. Curious about your diamond earnings? Uncover your monetary prowess effortlessly with TikTok Live Agency's cutting-edge converter!";
  const resultsRef = React.createRef();
  const [diamondsReceived, setDiamondsReceived] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [conversionRate, setConversionRate] = useState(null);
  const [creatorRank, setCreatorRank] = useState("");

  useEffect(() => {
    // Fetch the latest exchange rates from a free API
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch("https://open.er-api.com/v6/latest/USD"); // Replace 'USD' with the currency code of your country
        const data = await response.json();
        setConversionRate(data.rates.GBP);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, []);

  const calculateEarnings = () => {
    resultsRef.current.scrollIntoView({ behavior: "smooth" });
    const earningsPerDiamond = 5 / 1000;
    const earnings = diamondsReceived * earningsPerDiamond;
    setTotalEarnings(earnings);

    if (diamondsReceived > 500000) {
      setCreatorRank("VIP");
    } else if (diamondsReceived >= 100000) {
      setCreatorRank("Thriving");
    } else if (diamondsReceived >= 20000) {
      setCreatorRank("Striving");
    } else {
      setCreatorRank("Starter");
    }
  };
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div className="bg-white">
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-24 pt-10">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-1 md:hidden block mr-auto"></div>
          <h1 className="text-[#242527] font-semibold lg:text-[46px] text-left text-[28px] md:hidden block uppercase">
            TikTok Earnings
            <span className="text-[#EE1B50] font-bold"> Calculator</span>
          </h1>
          <div className="flex md:justify-between justify-center gap-5 items-center xl:flex-nowrap flex-wrap">
            <div className="md:max-w-[580px] w-full mx-auto">
              <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 md:block hidden"></div>
              <h1 className="text-[#242527] font-semibold lg:text-[46px] text-[28px] md:block hidden uppercase">
                TikTok Earnings
                <span className="text-[#EE1B50] font-bold"> Calculator</span>
              </h1>
              <div className="md:max-w-[550px] w-full mx-auto relative md:hidden block mt-4">
                <img src={Tik} alt="Image" className="w-full rounded-xl" />
              </div>

              <p className="mt-5 md:mb-10 mb-5 text-neutral-500 lg:text-base text-xs font-normal text-justify">
                Estimate and elevate your earnings! Our calculator gauges your
                income based on diamonds earned, offering personalized growth
                tips for live streaming success. Maximize your potential with
                precision and exclusive insights. Your journey to success starts
                here!
              </p>
              <p className="text-black lg:text-lg text-base font-semibold">
                Enter the number of diamonds you've earned:
              </p>
              <input
                value={diamondsReceived}
                onChange={(e) => setDiamondsReceived(e.target.value)}
                type="number"
                placeholder="Enter Diamonds here..."
                className="mt-3 bg-white outline-none text-neutral-500 text-base font-normal md:w-[518px] w-full md:h-[75px] h-11 lg:px-10 px-4 flex items-center rounded-[10px] er"
              />
              <button
                onClick={calculateEarnings}
                className="mt-5 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center md:w-[518px] w-full">
                Calculate Earnings
              </button>
            </div>
            <div ref={resultsRef}></div>

            <div className="md:max-w-[550px] w-full mx-auto relative md:block hidden">
              <img src={Tik} alt="Image" className="w-full rounded-xl" />
            </div>
          </div>
          {/* <input
            type="text"
            placeholder="Your Estimated Earnings"
            className="bg-white outline-none text-neutral-500 text-base font-normal mt-7 w-full md:h-[75px] h-[84px] lg:px-10 px-4 flex items-center rounded-[10px] er"
          /> */}

          <div className="bg-white outline-none text-neutral-500 text-base font-normal mt-7 w-full py-5 lg:px-10 px-4 flex items-center rounded-[10px] er">
            {totalEarnings > 0 && conversionRate !== null && (
              <div>
                <p>
                  You've earned <strong>${totalEarnings.toFixed(2)}</strong>{" "}
                  based on {diamondsReceived} diamonds.
                  <br />
                  <br />
                  In GBP, this is approximately
                  <strong>
                    £{(totalEarnings * conversionRate).toFixed(2)}
                  </strong>
                  .
                </p>
                <br />
                <p>
                  Your Creator Rank: <strong>{creatorRank}</strong>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function CalculatorMain() {
  return (
    <div className="relative lg:pt-10 pt-10">
      <Calculator />
    </div>
  );
}

export default CalculatorMain;
