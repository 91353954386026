import React from "react";

import { NavLink } from "react-router-dom";

function TermsMain() {
  return (
    <div className="relative lg:pt-10 pt-10">
      <div className="py-10">
        <div className="container mx-auto 2xl:px-0 px-4 xl:max-w-[1200px] w-full">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 mx-auto"></div>
          <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-bold text-center">
            <span className="text-[#EE1B50]">Live Creator Network</span> Terms{" "}
            <span className="text-[#EE1B50]">&</span> Conditions
          </h1>
          <p className="text-center text-[#737071] lg:text-base text-xs font-normal ">
            THE PRESTIGE PERFECTIONS LIMITED TERMS AND CONDITIONS IN RESPECT OF
            BEING A VERIFIED TIKTOK AGENCY
          </p>

          <div className="terms text-justify">
            <p>
              <b>Definitions:</b>
            </p>
            <p>
              &ldquo;Prestige Perfections&rdquo;, the &ldquo;Company&rdquo; or
              &ldquo;us&rdquo; means Prestige Perfections Limited.
            </p>
            <br />
            <p>
              &ldquo;CREATOR PROGRAM&rdquo; or &ldquo;program&rdquo; means the
              Prestige Perfections TikTok creator program you join as a host and
              includes any materials provided to you by the program including
              but not limited to communication channels, any/all training
              provided, event, seminar or conference.
            </p>
            <br />
            <p>
              &ldquo;CREATOR&rdquo;, the &ldquo;Hosts&rdquo; or
              &ldquo;you&rdquo; means the individual that is signing onto the
              program.
            </p>
            <br />
            <p>
              Please carefully read the following terms and conditions relating
              to your participation in Prestige Perfections&rsquo;s TikTok
              Creator Program, including and not limited to any online training,
              events, conference or communication channel. By registering for
              the Creator Program, you (the &lsquo;Creator&rdquo;) signify your
              acceptance of and obligation to these terms and conditions. If you
              have objections to the following Terms and Conditions, you should
              not register for, or join, the Creator Program.
            </p>

            <br />
            <p>
              <b>Creator Conduct</b>
            </p>
            <p>
              Agency requires all Creators to be respectful and professional to
              our staff, event staff, guest speakers, and other Creators and
              their guests or families throughout your time as an agency
              creator, even during non-scheduled downtime and breaks. Prestige
              Perfections reserves the right to ask Creators and/or their guests
              to leave the Agency program immediately should they be deemed
              rude, uncooperative, unprofessional, intoxicated or in possession
              of alcohol or any illegal substance. In such a case, the Creators
              will not be reimbursed any losses under any circumstances and they
              will not receive any further access to the agency or program
              without dispute.
            </p>
            <br />
            <p>
              All Creators that are part of the agency must be going Live On
              TikTok using the registered Agency TikTok account at least 7 days
              per month and meet a minimum of 15 hours live time per month.
              Failing to do so, the agency reserves the right to remove the
              creator from the agency. In the case of removal you the host must
              remove any and all Agency Branding, including and not limited to
              Agency mentions, Icons, Profile pictures containing the brand, and
              backgrounds that display the Agency logo or Brand. The Agency
              reserves the right to remove any Creator at any time from the
              program without dispute.&nbsp;
            </p>

            <br />
            <p>
              <b>Disclaimers</b>
            </p>
            <p>
              By law we cannot guarantee your satisfaction with our training or
              your results. Our Creator Program is for your educational and
              informational purposes only and is provided at no direct cost to
              the Creator. As stipulated by law, we make no guarantees that you
              will do well, achieve any results or make any money from our
              information, and we offer no professional, legal, therapeutic, or
              financial advice. Our training cannot replace or substitute for
              the services of certified professionals in any field, including,
              but not limited to, financial, health, or legal matters.
              Furthermore, we do not provide, represent or fund any mental
              health treatment as part of the program, it is your responsibility
              as a host to seek any professional help you may need without
              financial or counselling duty being passed over to the agency.
            </p>
            <br />
            <p>
              We do not purport anything we do or teach as a &ldquo;get rich
              scheme,&rdquo; and any financial numbers outlined in our training
              are examples, opinions and illustrations, and thus should not be
              considered average earnings, exact earnings, or promises for your
              actual or future performance. There can be no assurance that any
              prior successes, or past results, as to income earnings, can be
              used as an indication of your or anyone&rsquo;s future success or
              results. Check with your accountant, lawyer or professional
              advisor, before acting on this or any information.
            </p>
            <br />
            <p>
              The Agency abilities are limited to what our provider TikTok deems
              available to us and you as a host. If for any reason we cannot
              help you with a specific account, we shall not be held liable
              under any circumstances. Prestige Perfections reserve the right to
              amend the terms and conditions from time to time without any prior
              notice to creators.&nbsp;
            </p>

            <br />
            <p>
              <b>Liability Waiver</b>
            </p>
            <p>
              You alone are responsible and accountable for your decisions,
              actions and results in life, and by registering for our Program,
              you agree not to attempt to hold us liable for any such decisions,
              actions or results, at any time, under any circumstance. You agree
              that our company is not responsible for the success or failure of
              your decisions relating to any information presented by our
              company, or our company products or services, and you specifically
              release Agency, Prestige Perfections, or our representatives or
              contractors from liability for any special or consequential
              damages that result from the use of, or the inability to use, the
              information or strategies communicated through our Seminars,
              Events, Programs, materials, websites, or any services provided
              prior to or following this program, even if advised of the
              possibility of such damages or caused by negligence of the Agency
              or its representatives. Creator hereby accepts all risk to his or
              her finance and health excluding personal injury or death that may
              result from Agency negligence whilst the Creator has been
              participating in an on-site Seminar or event held by the agency.
              The agency reserves the right to remove any creator from the
              program without dispute.
            </p>

            <br />
            <p>
              <b>Resigning As A Host</b>
            </p>
            <p>
              If for any reason you as a Creator resign from your duties as a
              host/Creator. You will immediately be removed from any Agency
              training or communication channels. In the case of resignation,
              you as the host must remove any and all Agency Branding, including
              and not limited to Agency mentions, Icons, Profile pictures
              containing the brand, and backgrounds that display the Agency logo
              or Brand within 24 hours of resignation.
            </p>

            <p>
              Any host/Creator can only be removed as a host from the backstage
              30 days after joining. In the case of resignation prior to the
              initial 30 day joining period. All access to the Agency will be
              removed, and only after 30 days will you be officially released
              backstage. In cases where this is missed, the Agency shall not be
              held liable and the Creator can re-request release after the 30
              day has passed. The agency reserves the right to charge an admin
              fee for the resources involved in releasing the Creator from the
              agency.
            </p>

            <br />
            <p>
              <b>Intellectual Property</b>
            </p>
            <p>
              At all times, Prestige Perfections and/or its licensors, remain
              the owner of the intellectual property in the Online Courses and
              the Course Materials. Other than as specified below, no Online
              Course and/or Course Materials, nor any part of it may be
              reproduced, stored in a retrieval system or transmitted in any
              form or by any means without the prior written permission of
              Prestige Perfections.
            </p>
            <br />
            <p>
              In consideration of receipt by Prestige Perfections of your
              acceptance of the agency, Prestige Perfections grants to you a
              non-exclusive, non-transferable licence to access the Online
              Course and use the Course Materials content for the sole purpose
              of studying for the Online Course. For Online Study Materials, the
              licence granted is to use the Online Study Materials by an
              individual student only. Any Creator found sharing their access to
              non members will be held liable for misconduct and in breach of
              the terms. Prestige Perfections reserve the right to remove the
              creator from the program without dispute and remove any access to
              agency materials.
            </p>
            <br />
            <p>
              Save as expressly set out in the Online Terms or as otherwise
              agreed by Prestige Perfections pursuant to Additional Online Terms
              and Conditions, you may not modify, copy, reproduce, re-publish,
              sub-licence, sell, upload, broadcast, post, transmit, make
              available, disseminate or distribute in any way the Online Course
              or any of the Course Materials. You may not modify, adapt, merge,
              translate, disassemble, decompile, recompile or reverse engineer
              any software forming part of the Online Study Materials or create
              derivative works based on the whole of or any part, or which
              incorporate, the Online Study Materials into any software program.
              Access to the Online Course and use of the Course Materials not
              expressly permitted in the Online Terms is strictly prohibited and
              will constitute an infringement of either Prestige Perfections
              copyright or Prestige Perfections&rsquo;s other intellectual
              property rights, and/or the copyright or other intellectual
              property rights of Prestige Perfections&rsquo;s licensors.
            </p>

            <br />
            <p>
              <b>Earnings as a host</b>
            </p>
            <p>
              Creators earn a percentage commission from the gifts they receive
              on TikTok Live, these commissions are paid via TikTok directly via
              the app platform. Prestige Perfections are not responsible for
              your diamonds earned nor are they distributed via the agency.
              Prestige Perfections do not take any earnings directly from the
              Creators TikTok Live.
            </p>

            <br />
            <p>
              <b>Data Protection</b>
            </p>
            <p>
              Prestige Perfections will process the information it receives from
              you or otherwise holds about you in accordance with the Online
              Terms and its privacy policy. You consent to the use by Prestige
              Perfections of such information in accordance with Prestige
              Perfections privacy policy and the point highlighted in this
              sections:
            </p>

            <br />
            <p>
              <b>Consent</b>
            </p>
            <p>
              By using our website, products or services, you hereby consent to
              our Privacy Policy and this section and agree to its terms.
            </p>

            <br />
            <p>
              <b>Information we collect</b>
            </p>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information. In
              the case of the Creator program your details are used to process
              your applications, submit your request to join via Tiktok systems
              and communicate with you prior to and during your membership to
              the agency.
            </p>
            <br />
            <p>
              If you contact us directly or via our website, we may receive
              additional information about you such as your name, email address,
              phone number, the contents of the message and/or attachments you
              may send us, and any other information you may choose to provide.
            </p>
            <br />
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>

            <br />
            <p>
              <b>How we use your information</b>
            </p>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <p>
              &bull; Provide, operate, and maintain our website, products and
              services
            </p>
            <p>
              &bull; Improve, personalise, and expand our website, products and
              services
            </p>
            <p>
              &bull; Understand and analyse how you use our website, products
              and services
            </p>
            <p>
              &bull; Develop new products, services, features, and functionality
            </p>
            <p>
              &bull; Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, products
              and services, and for marketing and promotional purposes
            </p>
            <p>&bull; Send you emails, SMS and contact you via telephone</p>
            <p>&bull; Find and prevent fraud</p>
            <p>
              &bull; We will not share your information with third parties
              outside of TikTok and Prestige Perfections unless you agree to do
              so
            </p>

            <br />
            <p>
              <b>For Paid Events or Services</b>
            </p>
            <p>
              From time to time any event held at a venue may incur a charge to
              attend or a refundable deposit to ensure attendance. This will be
              stated on the announcement of a given event.&nbsp;
            </p>

            <br />
            <p>
              <b>Price</b>
            </p>
            <p>Where stated prices quoted are inclusive of VAT.</p>

            <br />
            <p>
              <b>Exclusions</b>
            </p>
            <p>
              Note that events are not residential and do not include
              accommodation or food and beverages unless stated.
            </p>

            <br />
            <p>
              <b>Payment Terms</b>
            </p>
            <p>
              These are generally &ldquo;pay in full&rdquo;. A discount is
              offered for one off payments. The overall price must be paid
              including any final instalment before the date of the event. If
              you are paying by instalment then the event you are booking must
              be sufficiently far ahead for all the instalment payments to have
              been made in advance of the event. All monies must be paid 6 clear
              days before attending an event.
            </p>

            <br />
            <p>
              <b>Consequences of Failure to Pay</b>
            </p>
            <p>
              If payment is not made within the time limits set out above, this
              will be a breach of contract by the client entitling Prestige
              Perfections to treat the contract as at an end, and reallocate the
              bookings and/or tickets without notice to the client. In the event
              of Prestige Perfections treating the contracts as at an end
              Prestige Perfections shall be entitled to retain all sums already
              paid by the client, and the balance, if any, of the price of the
              booking shall become immediately payable by the client to Prestige
              Perfections. This is without prejudice to Prestige
              Perfections&rsquo;s rights to claim damages from the client in
              respect of any loss suffered by Prestige Perfections.
            </p>

            <br />
            <p>
              <b>Cancellation or Variations by the Creator/Host/Client</b>
            </p>
            <p>
              Cancellation: Any notice of cancellation by the client of a
              booking or part of a booking must be made in writing by letter or
              email sent to Prestige Perfections. All bookings are subject to a
              14 day cancellation period, from the date of booking. Refunds will
              be made within 30 days of receipt of a correct cancellation
              notice. Once the Buyer registers for the Seminar or purchases the
              Program, Promoter makes extensive arrangements and investments
              while anticipating their tuition and attendance, and, in the case
              of their cancellation, incurs significant administration hassles,
              expenses, and loss of business. Given this fact we do not accept
              cancellations or issue refunds beginning 14 days after Buyer has
              registered for the Seminar or purchased the Program. No refunds
              will be given after this, this includes all sales made by a 3rd
              party at a Prestige Perfections event.
            </p>

            <br />
            <p>
              <b>Alterations to advertised packages or Programs</b>
            </p>
            <p>All advertised packages are subject to availability.</p>
            <br />
            <p>
              Every reasonable effort will be made to adhere to the advertised
              areas of the program, but any product or service may be altered or
              dates changed either before or after confirmation of membership.
              This can be for various reasons, the program may be adjusted from
              time to time so that the services and product can be improved for
              you.
            </p>
            <br />
            <p>For correspondence, contact: Saqib[at]PrestigePerfections.com</p>

            <NavLink to="/apply">
              <button className="md:mt-16 mt-7 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
                Apply Now
              </button>
            </NavLink>
            <br />
          </div>

          <p></p>
        </div>
      </div>
    </div>
  );
}

export default TermsMain;
