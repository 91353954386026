export const FooterMenuList = [
  {
    id: 1,
    name: "Let us Help you",
    list: [
      {
        id: 1,
        name: "Diamond Calculator",
        link: "/diamond-calculator",
      },
      {
        id: 2,
        name: "Articles",
        link: "/articles",
      },
      {
        id: 3,
        name: "About Us",
        link: "/about",
      },
      {
        id: 4,
        name: "Team",
        link: "/team",
      },
      // {
      //   id: 4,
      //   name: "Shipping",
      //   link: "/",
      // },
      // {
      //   id: 4,
      //   name: "Blog",
      //   link: "/",
      // },
    ],
  },
  {
    id: 1,
    name: "Make Money",
    list: [
      {
        id: 1,
        name: "Apply To Join",
        link: "/apply",
      },
      // {
      //   id: 2,
      //   name: "Become a Influencer",
      //   link: "/",
      // },
      // {
      //   id: 3,
      //   name: "How it works",
      //   link: "/",
      // },
      // {
      //   id: 4,
      //   name: "Career",
      //   link: "/",
      // },
      {
        id: 5,
        name: "FAQ",
        link: "/faqs",
      },
    ],
  },
  {
    id: 1,
    name: "Legal",
    list: [
      // {
      //   id: 2,
      //   name: "Contact Us",
      //   link: "/contact",
      // },
      {
        id: 1,
        name: "Terms & Conditions",
        link: "/terms-and-conditions",
      },

      {
        id: 3,
        name: "Privacy Policy",
        link: "/privacy-policy",
      },
      {
        id: 3,
        name: "Sitemap",
        link: "/sitemap",
      },
    ],
  },
];
