import React from "react";
import Tik from "../../assets/images/Home/tik.png";
import { NavLink } from "react-router-dom";

const Tiktock = () => {
  return (
    <>
      <div className="bg-white">
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-24 pt-20">
          <div className="flex md:justify-between justify-center gap-5 items-center xl:flex-nowrap flex-wrap">
            <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-1 md:hidden block mr-auto"></div>
            <h1 className="text-[#242527] font-bold lg:text-[46px] text-[28px] md:hidden block uppercase">
              Who Is This{" "}
              <span className="text-[#EE1B50] font-bold"> TikTok Live</span>{" "}
              Program For?
            </h1>
            <div className="md:max-w-[515px] w-full mx-auto relative">
              <button className="absolute right-4 top-4 text-center text-white md:text-base shadow bg-gradient-to-r  from-[#EE1B50] to-[#BB153F] hover:from-[#BB153F] hover:to-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-[72px] h-10 flex justify-center items-center md:w-[283px] w-[270px]">
                Check you Eligibility Criteria
              </button>
              <img src={Tik} alt="Image" className="w-full rounded-xl" />
            </div>
            <div className="md:max-w-[625px] w-full mx-auto">
              <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 md:block hidden"></div>
              <h1 className="text-[#242527] font-bold lg:text-[46px] text-[28px] md:block hidden uppercase">
                Who Is This{" "}
                <span className="text-[#EE1B50] font-bold"> TikTok Live</span>{" "}
                Program For?
              </h1>
              <div className="mt-8 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-8 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You must have between 1,000 and 100,000 followers
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-11 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You must not have earned more than 150,000 diamonds in the
                  last 30 days
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-8 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You must be from the UK
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-8 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You follow content guidelines and are not in breach of them{" "}
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-8 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You do not vape/smoke on your TikTok’s{" "}
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-8 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  Adhere to TikTok's community guidelines{" "}
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-11 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  We do not accept backup accounts, it must be your main account
                  you go LIVE on{" "}
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50] after:absolute after:w-1 after:h-11 after:bg-[#FEE8EE] after:left-0 after:right-0 after:top-5 after:mx-auto"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  You must be willing to go LIVE 7 times per month (over 60 mins
                  each) and achieve a minimum of 15 hours total per month
                </p>
              </div>
              <div className="mt-2 flex md:gap-7 gap-3 items-start relative">
                <div className="md:w-5 w-4 relative mt-1">
                  <div className="md:w-5 w-4 md:h-5 h-4 rounded-full bg-[#EE1B50]"></div>
                </div>
                <p className="text-neutral-500 lg:text-lg text-sm font-normal">
                  Must be over the age of 18
                </p>
              </div>
            </div>
          </div>
          <p className="text-neutral-500 md:mt-10 mt-5 lg:text-lg text-sm font-normal md:px-4 px-0">
            This program is designed for rising creators. If you have a large
            following on other platforms such as Instagram or Snapchat; it is
            very easy for you to promote your TikTok account to your other
            social audience to grow your following, and thus be eligible for
            this program.
          </p>

          <NavLink to="/apply">
            <button className="md:mt-16 mt-7 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
              Apply Now
            </button>
          </NavLink>
          <br />
        </div>
      </div>
    </>
  );
};

export default Tiktock;
