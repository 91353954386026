import React, { useState, useEffect } from "react";
import "./articles.css";
import { useNavigate } from "react-router-dom";


function Articles() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleArticleClick = (article) => {
    navigate(`/articles/${article.URL}`, {
      state: { article, authors, categories },
    });
  };
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const responses = await Promise.all([
          fetch("https://tiktokliveagency.com/tiktok/authors/fetchAuthors", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
          fetch(
            "https://tiktokliveagency.com/tiktok/categories/fetchCategories",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Sclout",
              },
            }
          ),
          fetch("https://tiktokliveagency.com/tiktok/articles/fetchArticles", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
        ]);

        const [authorsRes, categoriesRes, articlesRes] = await Promise.all(
          responses.map((res) => res.json())
        );

        if (!authorsRes.data || !categoriesRes.data || !articlesRes.data) {
          throw new Error("Failed to fetch data");
        }

        setAuthors(authorsRes.data.authors);
        setCategories(categoriesRes.data);
        setArticles(articlesRes.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const formatPublishedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };


  return (
    <div className="team_main">
      <h2> All Articles </h2>
      <div className="articles-container">
        {articles.map((article) => (
          <div
            onClick={() => handleArticleClick(article)}
            key={article.id}
            className="article-card">
            <h3>{article.title}</h3>
            <p>{formatPublishedDate(article.publishedAt)} |  {authors.find((author) => author.id === article.authorID)?.name}</p>
           
            {/* <div style={{ display: "flex", flexDirection: "row" }}>
              <p>
                Author:{" "}
                {authors.find((author) => author.id === article.authorID)?.name}
              </p>
              <p style={{ marginLeft: 20 }}>
                | Category:{" "}
                {
                  categories.find(
                    (category) => category.CategoryID === article.categoryID
                  )?.CategoryName
                }
              </p>
            </div> */}
            <img
              style={{ height: 350}}
              src={article.image}
              alt={article.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
