import React from "react";
import Apply from "./pages/Apply/ApplyMain";
function apply() {
  return (
    <>
      <Apply />
    </>
  );
}
export default apply;
