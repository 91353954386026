import React from "react";
import FooterActionList from "./FooterActionList";
import ScrollToTopButton from "../../components/ScrollToTop"

function Footer(props) {
  return (
    <footer id="Footer" className="relative z-50 bg-black">
      <FooterActionList></FooterActionList>
      <ScrollToTopButton />
    </footer>
  );
}

export default Footer;
