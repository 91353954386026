import React, { useEffect, useState } from "react";
import "./adminPage.css";
import { useNavigate } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToMarkdown from "draftjs-to-markdown";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import axios from "axios";
import { stateToHTML } from "draft-js-export-html";


function AddArticle() {
  const [articles, setArticles] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState(null)
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    publishedAt: "",
    authorID: "",
    image: "",
    categoryID: "",
    URL: "",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    // const editorContentAsMarkdown = draftToMarkdown(
    //   convertToRaw(editorState.getCurrentContent())
    // );
    let abs = stateToHTML(editorState.getCurrentContent())

  };
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      image: event.target.files[0],
    });
    setFile(event.target.files[0]);
  };
  const [convertedContent, setConvertedContent] = useState(null);

  // useEffect(() => {
  //   let html = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(html);
  //   console.log("adi",html)
  // }, [editorState]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateURL = async (title) => {
    let a =
      title.toLowerCase().replace(/ /g, "-") +
      "-" +
      Math.floor(Math.random() * 1000);
    return a;
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const responses = await Promise.all([
          fetch("https://tiktokliveagency.com/tiktok/authors/fetchAuthors", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
          fetch(
            "https://tiktokliveagency.com/tiktok/categories/fetchCategories",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Sclout",
              },
            }
          ),
          fetch("https://tiktokliveagency.com/tiktok/articles/fetchArticles", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }),
        ]);

        const [authorsRes, categoriesRes, articlesRes] = await Promise.all(
          responses.map((res) => res.json())
        );

        if (!authorsRes.data || !categoriesRes.data || !articlesRes.data) {
          throw new Error("Failed to fetch data");
        }

        setAuthors(authorsRes.data.authors);
        setCategories(categoriesRes.data);
        setArticles(articlesRes.data);

      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = await generateURL(formData.title);

      // Create FormData object
      const formDataWithUrl = new FormData();
      // // const editorContentAsMarkdown = JSON.stringify(
      // //   convertToRaw(editorState.getCurrentContent())
      // // );
       const editorContentAsHTML = stateToHTML(
         editorState.getCurrentContent()
       );
      formDataWithUrl.append("title", formData.title);
      formDataWithUrl.append("content", editorContentAsHTML.toString());
      formDataWithUrl.append("publishedAt", formData.publishedAt);
      formDataWithUrl.append("authorID", formData.authorID);
      formDataWithUrl.append("categoryID", formData.categoryID);
      formDataWithUrl.append("URL", url);
      formDataWithUrl.append("image", file); // Append file

      // Log FormData values
      //print all form data values
      for (var value of formDataWithUrl.values()) { console.log(value); }

      // Send form data
      const response = await axios.post(
        "https://tiktokliveagency.com/tiktok/articles/create",
        formDataWithUrl,
        {
          headers: {
            Authorization: "Sclout",
            "Content-Type": "multipart/form-data", // Set content type
          },
        }
      );

      if (response.status === 201) {
        console.log("Article added successfully!");
        alert("Article added successfully!");
        setFormData({
          title: "",
          content: "",
          publishedAt: "",
          authorID: "",
          image: "",
          categoryID: "",
          URL: "",
        });
      }

      if (!response.ok) {
        throw new Error("Failed to add article");
      }
    } catch (error) {
      console.error("Error adding article:", error);
    }
  };




  const handleEdit = (articleId) => {
    navigate(`/edit-article/${articleId}`);
  };

  const handleDelete = async (articleId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this article?"
      );
      if (confirmDelete) {
        const response = await fetch(
          `https://tiktokliveagency.com/tiktok/articles/delete?id=${articleId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to delete article");
        }
        console.log("Article deleted successfully!");
        alert("Article deleted successfully!");
        const updatedArticles = articles.filter(
          (article) => article.id !== articleId
        );
        setArticles(updatedArticles);
      }
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  return (


    <div className="content-container">

      <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>Add A New Article</h1>

      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="title" className="field-label">
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className="input-field"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group2">
          <label htmlFor="content" className="field-label">
            Content:
          </label>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-content"
            editorStyle={{ minHeight: "300px" }}
            toolbar={{
              options: ["inline", "link"],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              link: {
                popupClassName: "link-popup",
              },
            }}
          />
        </div>
        <div className="input-group">
          <label htmlFor="publishedAt" className="field-label">
            Published At:
          </label>
          <input
            type="datetime-local"
            id="publishedAt"
            name="publishedAt"
            className="input-field"
            value={formData.publishedAt}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="authorID" className="field-label">
            Author:
          </label>
          <select
            id="authorID"
            name="authorID"
            className="input-field"
            value={formData.authorID}
            onChange={handleInputChange}>
            <option value="">Select an author</option>
            {authors.map((author) => (
              <option key={author.id} value={author.id}>
                {author.name}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="categoryID" className="field-label">
            Category:
          </label>
          <select
            id="categoryID"
            name="categoryID"
            className="input-field"
            value={formData.categoryID}
            onChange={handleInputChange}>
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.CategoryID} value={category.CategoryName}>
                {category.CategoryName}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="image" className="field-label">
            Image :
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
          />
        </div>

        <button type="submit" className="submit-button">Submit</button>
      </form>

    </div>
  );
}

export default AddArticle;
