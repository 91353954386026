import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import male from '../../assets/teams/male-placeholder.png';
import female from '../../assets/teams/female-placeholder.png';

function Team() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    // Fetch team members from the API
    fetch('https://tiktokliveagency.com/tiktok/team/fe_team')
      .then(response => response.json())
      .then(data => setTeamMembers(data))
      .catch(error => console.error('Error fetching team members:', error));
  }, []);

  const getPlaceholderImage = (gender) => {
    return gender === '1' ? male : female;
  };

  return (
    <>
      <Helmet>
        <title>Team | Prestige Perfections</title>
        <meta
          name="description"
          content="Committed to provide absolute finesse! Meet our team of experts at Prestige Perfections Web Agency, the pinnacle of digital ingenuity where every pixel, code, and concept converges to redefine your online presence."
        />
      </Helmet>
      <div className="team_main">
        <h1 className="world_1">
          MEET OUR <span className="world_2"> TEAM OF EXPERTS</span>
        </h1>
        <p className="world_p">Committed to provide absolute finesse!</p>
        <div className="team_1">
          {teamMembers.map((member, index) => (
            <div className="team_2" key={member.id}>
              <img
                src={member.image ? member.image : getPlaceholderImage(member.gender)}
                alt={member.name}
                className="teamimg"
              />
              <h1>{member.name}</h1>
              <p>{member.role} | {member.country} </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Team;