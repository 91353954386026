import React from "react";
import Faq from "../src/pages/Faq/FaqsMain";
function faq() {
  return (
    <>
      <Faq />
    </>
  );
}
export default faq;
