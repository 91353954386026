import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const ViewApplications = () => {
  const [applications, setApplications] = useState([]);
  const [eligibleApplications, setEligibleApplications] = useState([]);
  const [welcomedApplications, setWelcomedApplications] = useState([]);
  const [rejectedApplications, setRejectedApplications] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const fetchApplications = async () => {
    try {
      const response = await fetch(
        "https://tiktokliveagency.com/tiktok/applications/viewApplications",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Sclout",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch applications");
      }
      const data = await response.json();
      setApplications(data);

      const eligible = data.filter(
        (application) => application.elegible !== "1" && application.elegible !== "2"
      );

      const welcomed = data.filter(
        (application) =>
          (application.wc_email === "1" || application.wc_email === "0") &&
          application.elegible === "1"
      );
      const rejected = data.filter(
        (application) => application.elegible === "2"
      );
      setEligibleApplications(eligible);
      setWelcomedApplications(welcomed);
      setRejectedApplications(rejected);
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const handleReject = async (applicationId) => {
    try {
      const confirmReject = window.confirm(
        "Are you sure you want to reject this application?"
      );
      if (confirmReject) {
        const response = await fetch(
          `https://tiktokliveagency.com/tiktok/applications/reject_application?id=${applicationId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to reject application");
        }
        console.log("Application rejected successfully!");
        alert("Application rejected successfully!");
        fetchApplications(); 
      }
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
  };

  const handleApprove = async (applicationId) => {
    try {
      const confirmApprove = window.confirm(
        "Are you sure you want to approve this application?"
      );
      if (confirmApprove) {
        const response = await fetch(
          `https://tiktokliveagency.com/tiktok/applications/approve_application?id=${applicationId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Sclout",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to approve application");
        }
        console.log("Application approved successfully!");
        alert("Application approved successfully!");
        fetchApplications(); // Refresh the table after successful approval
      }
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  const handleWelcome = async (applicationId) => {
    try {
      const formData = new FormData();
      formData.append("applicationId", applicationId);

      const response = await fetch(
        "https://tiktokliveagency.com/tiktok/applications/wc_email",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send welcome email");
      }

      const data = await response.text();
      console.log(data);
      fetchApplications();
    } catch (error) {
      console.error("Error sending welcome email:", error);
    }
  };

  return (
    <>
      <div className="admincontainer">
        <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
          New Applications
        </h1>
        <table className="applications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Number</th>
              <th>Country</th>
              <th>Followers</th>
              <th>Invitation Code</th>
              <th>Eligible</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {eligibleApplications.map((application) => (
              <tr key={application.id}>
                <td>{application.id}</td>
                <td>{application.name}</td>
                <td>{application.username}</td>
                <td>{application.email}</td>
                <td>{application.phone_number}</td>
                <td>{application.country}</td>
                <td>{application.followers_count}</td>
                <td>{application.invite_code}</td>
                <td>{application.elegible}</td>
                <td className="action-buttons">
                  {application.elegible !== "2" && (
                    <>
                      <button
                        onClick={() => handleApprove(application.id)}
                        className="approve-button"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleReject(application.id)}
                        className="delete-button"
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
          Approved Applications
        </h1>
        <table className="applications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Number</th>
              <th>Country</th>
              <th>Followers</th>
              <th>Invitation Code</th>
              <th>Eligible</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {welcomedApplications.map((application) => (
              <tr key={application.id}>
                <td>{application.id}</td>
                <td>{application.name}</td>
                <td>{application.username}</td>
                <td>{application.email}</td>
                <td>{application.phone_number}</td>
                <td>{application.country}</td>
                <td>{application.followers_count}</td>
                <td>{application.invite_code}</td>
                <td>Yes</td>
                <td className="action-buttons">
                  {application.wc_email === "1" ? (
                    <p>Joined The Agency</p>
                  ) : (
                    <button
                      className="welcome-button"
                      onClick={() => handleWelcome(application.id)}
                    >
                      Welcome
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
          Rejected Applications
        </h1>
        <table className="applications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Number</th>
              <th>Country</th>
              <th>Followers</th>
              <th>Invitation Code</th>
            </tr>
          </thead>
          <tbody>
            {rejectedApplications.map((application) => (
              <tr key={application.id}>
                <td>{application.id}</td>
                <td>{application.name}</td>
                <td>{application.username}</td>
                <td>{application.email}</td>
                <td>{application.phone_number}</td>
                <td>{application.country}</td>
                <td>{application.followers_count}</td>
                <td>{application.invite_code}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewApplications;