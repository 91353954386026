import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ViewCommunication = () => {
  const [communications, setCommunications] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchCommunications();
  }, []);

  const fetchCommunications = async () => {
    try {
      const response = await axios.get("https://tiktokliveagency.com/tiktok/communications/get_communications");
      setCommunications(response.data);
    } catch (error) {
      console.error("Error fetching communications:", error);
    }
  };
  const handleComms = () => {
    navigate("/AddCommunication");
  };

  return (
    <>
      <div className="admincontainer">
        <h1 style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
          Communications
        </h1>
        <button className="submit-button" onClick={handleComms}>Add Host</button><br/>
        <table className="applications-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Hosts</th>
              <th>Method</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {communications.map((communication) => (
              <tr key={communication.id}>
                <td>{communication.id}</td>
                <td>{communication.date}</td>
                <td>{communication.hosts}</td>
                <td>{communication.method}</td>
                <td>{communication.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewCommunication;