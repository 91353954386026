import React, { useState, useEffect } from "react";
import axios from "axios";

const Sitemap = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tiktokliveagency.com/tiktok/sitemapGeneration"
        );
        setHtmlContent(response.data);
      } catch (error) {
        console.error("Error fetching HTML:", error);
      }
    };

    fetchData();
  }, []);

  return <div style={{display:'flex',flex:1,flexDirection:'column'}} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default Sitemap;
