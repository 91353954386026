export const NavbarList = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 3,
    name: "Diamond Calculator",
    link: "/diamond-calculator",
  },
  {
    id: 4,
    name: "About Us",
    link: "/about",
  },
  {
    id: 2,
    name: "FAQ’s",
    link: "/faqs",
  },
  {
    id: 4,
    name: "Team",
    link: "/team",
  },
  {
    id: 4,
    name: "Articles",
    link: "/articles",
  },
];

export const AdminNavbarList = [
  {
    id: 1,
    name: "Applications",
    link: "/applications",
  },
  {
    id: 5,
    name: "Hosts",
    link: "/HostRoster",
  },
  {
    id: 2,
    name: "Articles",
    link: "/allnews",
  },
  {
    id: 3,
    name: 'Team',
    link: '/viewTeam',
  },
  {
    id: 4,
    name: "FAQ’s",
    link: "/viewFaqs",
  },
  {
    id: 5,
    name: "Communication",
    link: "/ViewCommunication",
  },
  // {
  //   id: 5,
  //   name: "Add Host",
  //   link: "/AddHost",
  // },

];
