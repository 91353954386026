import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Home/logo.png";
import BurgerM from "../../assets/icons/burger.svg";
import { NavbarList } from "../../data/NavbarList";
import { AdminNavbarList } from "../../data/NavbarList";
import Cross from "../../assets/icons/cross.svg";
import { NavLink } from "react-router-dom";

function Navbar() {
  const currentPath = useLocation().pathname;
  const [navToggle, setNavToggle] = useState(false);
  const [loginclick, setLoginclick] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setIsAdmin(true);
    }
  }, [token, isAdmin]);

  const toggleNavigation = () => {
    setNavToggle(!navToggle);
  };
  const handleLogout = () => {
    localStorage.clear();
    setIsAdmin(false);
  };
  const headeritems = () => {
    setNavToggle(false);
  };

  // const OpenALink = () => {
  //   // Check if the page link exists
  //   if (j.link) {
  //     // Scroll to the top of the window
  //     window.scrollTo(0, 0);
  //   }
  // };

  const handleOnClick = () => {
    headeritems();
    // openALink();
  };

  return (
    <nav className="pt-[0px] w-full z-[500] bg-black fixed">
      <div className="container mx-auto xl:max-w-[1250px] w-full">
        <div className="flex items-center justify-between container mx-auto 2xl:px-0 px-4 md:py-2 py-2">
          <Link to="/" className="text-lu-secondary font-medium text-[18px]">
            <img src={Logo} alt="logo" className="md:w-[175px] w-[100px]" />
          </Link>
          {/* navlist */}
          <ul
            className={`fixed w-full lg:pt-0 pt-10 bg-lu-secondary h-[100vh] top-0 sidebar overflow-auto xl:overflow-visible duration-200 z-[100] ${
              navToggle ? "left-0" : "-left-[1350px]"
            } xl:h-[auto] xl:bg-[transparent] xl:w-[auto] xl:static xl:flex items-center gap-[20px] bg-black`}>
            <div className="lg:hidden flex justify-end mr-20 absolute top-5 left-0 right-0 w-full mx-auto ">
              <button onClick={toggleNavigation}>
                <img src={Cross} alt="Close" className="mx-auto w-full" />
              </button>
            </div>
            {isAdmin
              ? AdminNavbarList.map((v, i) => {
                  return (
                    <li
                      key={`${v.id}_${i}`}
                      className="xl:h-[80px] lg:leading-[80px] leading-[50px] mr-[20px] relative group px-[15px] xl:px-0 text-center">
                      <Link
                        to={v.link}
                        onClick={handleOnClick}
                        className={`text-[#C4C4C4] hover:text-[#EE1B50] border-b border-transparent xl:text-lu-secondary xl:font-[500] text-lg hover:text-lu-primary-2 font-normal xl:hover:text-lu-dark ${
                          currentPath === v.link ? "clickcolor pb-2" : ""
                        }`}>
                        {v.name}
                      </Link>

                      {v?.list && (
                        <ul className="dropdown_list nav_arrow text-[#C4C4C4] xl:hidden group-hover:block box-shadow-dropdown static xl:absolute xl:top-[70px] xl:bg-lu-white xl:shadow-dropdown">
                          {v?.list.map((j) => {
                            return (
                              <li
                                key={j.id}
                                className="xl:hover:bg-lu-primary-2">
                                <Link
                                  to={j.link}
                                  className="block text-xs leading-[50px] xl:leading-[80px] px-[25px] xl:px-[50px] whitespace-nowrap text-lu-white xl:text-lu-dark hover:text-lu-primary-2 xl:hover:text-lu-white durartion-200 text-white">
                                  {j.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })
              : NavbarList.map((v, i) => {
                  return (
                    <li
                      key={`${v.id}_${i}`}
                      className="xl:h-[80px] lg:leading-[80px] leading-[50px] mr-[20px] relative group px-[15px] xl:px-0 text-center">
                      <Link
                        to={v.link}
                        onClick={handleOnClick}
                        className={`text-[#C4C4C4] hover:text-[#EE1B50] border-b border-transparent xl:text-lu-secondary xl:font-[500] text-lg hover:text-lu-primary-2 font-normal xl:hover:text-lu-dark ${
                          currentPath === v.link ? "clickcolor pb-2" : ""
                        }`}>
                        {v.name}
                      </Link>

                      {v?.list && (
                        <ul className="dropdown_list nav_arrow text-[#C4C4C4] xl:hidden group-hover:block box-shadow-dropdown static xl:absolute xl:top-[70px] xl:bg-lu-white xl:shadow-dropdown">
                          {v?.list.map((j) => {
                            return (
                              <li
                                key={j.id}
                                className="xl:hover:bg-lu-primary-2">
                                <Link
                                  to={j.link}
                                  className="block text-xs leading-[50px] xl:leading-[80px] px-[25px] xl:px-[50px] whitespace-nowrap text-lu-white xl:text-lu-dark hover:text-lu-primary-2 xl:hover:text-lu-white durartion-200 text-white">
                                  {j.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
          </ul>
          <ul className="flex items-center gap-[20px] relative z-10">
            {isAdmin ? (
              <NavLink to="/">
                <button
                  onClick={handleLogout}
                  className="text-center text-white md:text-base bg-[#FF0000] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-sm font-semibold rounded-[10px] h-16 md:flex hidden justify-center items-center md:w-60 w-48">
                  Log Out
                </button>
              </NavLink>
            ) : (
              <NavLink to="/apply">
                <button className="text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-sm font-semibold rounded-[10px] h-16 md:flex hidden justify-center items-center md:w-60 w-48">
                  Join The Agency
                </button>
              </NavLink>
            )}
            <li className="mr-[1px] flex xl:hidden ">
              <button onClick={toggleNavigation}>
                {navToggle ? (
                  <img src={Cross} alt="Close" />
                ) : (
                  <img src={BurgerM} alt="Open" />
                )}
              </button>
              {/* </button> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
