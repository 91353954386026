import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Herobg from "../../assets/images/Home/hero.png";
// import Tik from "../../assets/icons/tiktok.svg";
import Live from "../../assets/images/Home/Live.jpg";

const Hero = ({ scrollToForm }) => {
  return (
    <>
      <div className="relative lg:pt-10 pt-10">
        <img
          src={Herobg}
          alt="Image"
          className="absolute -z-10 w-full lg:h-[680px] h-full lg:object-fill object-cover"
        />
        <div className="xl:max-w-[1250px] w-full mx-auto 2xl:px-0 px-4 lg:pt-16 pt-10 lg:pb-20 pb-14 relative z-40 lg:h-[740px] h-full flex items-center">
          <div className="md:max-w-[1100px] w-full mx-auto">
            <h1 className="text-white lg:text-[64px] text-[32px] font-medium">
              Prestige Perfections<br />{" "}
              <span className="text-[#EE1B50] font-bold"> TikTok Live </span>
              Agency
            </h1>
            <p className="text-stone-300 text-lg font-medium mt-8 md:mb-16 mb-8">
              The biggest opportunity to grow your following and income on
              Social Media right now is TikTok Live!
            </p>
            <div className="flex gap-8 items-center flex-wrap">
              <NavLink to="/apply">
                <button className="text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-sm font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center md:w-[200px] w-full">
                  Apply Now
                </button>
              </NavLink>
              <img src={Live} alt="Image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
