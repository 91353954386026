import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditHost = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    number: "",
    country: "",
    creator_status: "",
    start: "",
    end: "",
    contact_pref: "",
  });
  const navigate = useNavigate();
  const { hostId } = useParams();

  useEffect(() => {
    fetchHost();
  }, []);

  const fetchHost = async () => {
    try {
      const response = await axios.get(`https://tiktokliveagency.com/tiktok/hosts/get_host?id=${hostId}`);
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching host:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://tiktokliveagency.com/tiktok/hosts/update_host?id=${hostId}`, formData);
      navigate("/HostRoster");
    } catch (error) {
      console.error("Error updating host:", error);
    }
  };

  return (
    <div className="admincontainer">
      <div className="add-host-container">
        <h2>Edit Host</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="number">Number:</label>
            <input
              type="text"
              id="number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option value="">Select Country</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="MENA">MENA</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="creator_status">Creator Status:</label>
            <select
              id="creator_status"
              name="creator_status"
              value={formData.creator_status}
              onChange={handleChange}
              required
            >
              <option value="">Select Creator Status</option>
              <option value="Rookie">Rookie</option>
              <option value="Veteran">Veteran</option>
              <option value="Pro">Pro</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="start">Start Date:</label>
            <input
              type="date"
              id="start"
              name="start"
              value={formData.start}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="end">End Date:</label>
            <input
              type="date"
              id="end"
              name="end"
              value={formData.end}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact_pref">Contact Preference:</label>
            <select
              id="contact_pref"
              name="contact_pref"
              value={formData.contact_pref}
              onChange={handleChange}
              required
            >
              <option value="">Select Contact Preference</option>
              <option value="Direct Call">Direct Call</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="SMS">SMS</option>
              <option value="Email">Email</option>
            </select>
          </div>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  );
};

export default EditHost;