import React from "react";
import { Helmet } from "react-helmet";
import Privacy from "./pages/PrivacyPolicy/PrivacyMain";
function privacy() {
  const pageTitle = "Privacy Policy | TikTok Live Agency";
  const metaDescription =
    "Discover the story behind TikTok Live Agency. Learn about our mission, values, and the vibrant community we've built. Explore the exciting journey of creators and influencers. Join us on TikTok and be a part of our thriving community!";
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Privacy />
    </>
  );
}
export default privacy;
