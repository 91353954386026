import React from "react";

import { NavLink } from "react-router-dom";

function PrivacyMain() {


  return (
    <div className="relative lg:pt-10 pt-10">
      <div className="py-10">
        <div className="container mx-auto 2xl:px-0 px-4 xl:max-w-[1200px] w-full">
          <div className="w-[90px] h-[9px] bg-[#EE1B50] mb-3 mx-auto"></div>
          <h1 className="text-neutral-800 lg:text-[46px] text-[28px] font-bold text-center">
            <span className="text-[#EE1B50]">TikTok Live Agency</span> Pricacy
            Policy
          </h1>

          <div className="terms">
            <p>
              <strong>Introduction:</strong>
              <br />
              Welcome to tiktokliveagency.com (the "Site"), owned and operated
              by Prestige Perfections UK+ ("we," "us," or "our"). This Privacy
              Policy outlines our practices concerning the collection, use, and
              disclosure of personal information when you visit our website and
              use our services.
              <br />
              <br />
              <strong>Information We Collect:</strong>
              <br />
              When you visit tiktokliveagency.com, we automatically collect
              certain information about your device, including information about
              your web browser, IP address, time zone, and some of the cookies
              that are installed on your device. Additionally, as part of our
              creator onboarding process, we collect personal details such as
              your name, contact information, and relevant credentials.
              <br />
              <br />
              <strong>How We Use Your Information:</strong>
              <br />
              We use the information we collect to operate and maintain our
              website, analyze user behavior, and improve our services. The
              personal details collected during the creator onboarding process
              are used solely to assess qualification and contact qualified
              individuals.
              <br />
              <br />
              <strong>Data Security:</strong>
              <br />
              We take the security of your personal information seriously. We
              implement reasonable measures to protect your data from
              unauthorized access, disclosure, alteration, and destruction.
              <br />
              <br />
              <strong>Disclosure to Third Parties:</strong>
              <br />
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties. This does not include trusted
              third parties who assist us in operating our website, conducting
              our business, or servicing you, as long as those parties agree to
              keep this information confidential.
              <br />
              <br />
              <strong>Your Choices:</strong>
              <br />
              You have the right to request access to the personal information
              we hold about you. You may also request corrections or the
              deletion of your personal information. To do so, please contact us
              at{" "}
              <a href="mailto:contact@tiktokliveagency.com">
                contact@tiktokliveagency.com
              </a>
              .<br />
              <br />
              <strong>Updates to this Privacy Policy:</strong>
              <br />
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We encourage you to review this Privacy Policy
              periodically.
              <br />
              <br />
              <strong>Contact Us:</strong>
              <br />
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at{" "}
              <a href="mailto:contact@tiktokliveagency.com">
                contact@tiktokliveagency.com
              </a>
              .
            </p>

            <NavLink to="/apply">
              <button className="md:mt-16 mt-7 text-center text-white md:text-base bg-[#EE1B50] hover:bg-transparent border border-[#EE1B50] hover:text-[#EE1B50] duration-300 text-base font-semibold rounded-[10px] md:h-16 h-10 flex justify-center items-center w-full">
                Apply Now
              </button>
            </NavLink>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyMain;
